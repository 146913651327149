import React from "react"

import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl'
import CustomNavbar from '../components/CustomNavbar';
import BannerData from '../components/Banner/BannerData';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';
import { CookieBanner } from '@palmabit/react-cookie-law';

import '../assets/themify-icon/themify-icons.css';
import '../assets/simple-line-icon/simple-line-icons.css';
import '../assets/elagent/style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/animate.css';
import '../assets/bootstrap.min.css';
import '../assets/main.css';
import '../assets/responsive.css';
import "./layout.css"
import "./css/main.scss"
import "./vendors/bootstrap-selector/css/bootstrap-select.min.css"
import "./vendors/flaticon/flaticon.css"
import "./vendors/themify-icon/themify-icons.css"
import "./vendors/animation/animate.css"
import "./vendors/magnify-pop/magnific-popup.css"
import "./vendors/elagent/style.css"
import WelcomeSection from "./MainSite/WelcomeSection";
import Services from "./MainSite/Services";
import KnowHow from "./MainSite/KnowHow";
import CallToAction from "./MainSite/CallToAction";
import SEO from "./seo";
import {any} from "prop-types";

const Layout = ({ children, location }) => {
    // const intl = useIntl()

    /**
    const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          languages {
                defaultLangKey
                langs
              }
        }
      }
    }
  `)

    const messages = {
        'de': messages_de,
        'en': messages_en
    };

    const url = location.pathname;
    const { langs, defaultLangKey } = data.site.siteMetadata.languages;
    const langKey = getCurrentLangKey(langs, defaultLangKey, url);
    const homeLink = `/${langKey}`.replace(`/${defaultLangKey}/`, '/');
    const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));
    addLocaleData([...locale_en, ...locale_de]);
     **/

    return (
        <>
          <SEO />
            <div className="body_wrapper">
              <CustomNavbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
              <WelcomeSection BannerData={BannerData}/>
              <Services/>
              <KnowHow />
              <CallToAction/>
              <Footer FooterData={FooterData} showAddress={true}/>
              <CookieBanner
                message={
                  <FormattedHTMLMessage id="cookie.message" />
                }
                privacyPolicyLinkText={
                  <FormattedMessage id="cookie.privacypolicy" />
                }
                necessaryOptionText={
                  <FormattedMessage id="cookie.necessaryOptionText" />
                }
                preferencesOptionText={
                  <FormattedMessage id="cookie.preferencesOptionText" />
                }
                statisticsOptionText={
                  <FormattedMessage id="cookie.statisticsOptionText" />
                }
                marketingOptionText={
                  <FormattedMessage id="cookie.marketingOptionText" />
                }
                acceptButtonText={
                  <FormattedMessage id="cookie.acceptButtonText" />
                }
                styles={{
                  dialog: {},
                  container: {},
                  checkbox: {},
                  optionWrapper: {},
                  optionLabel: {},
                  selectPane: {},
                  policy: {}
                }}
                onAccept = {() => {
                  window.dataLayer.push({ event: `gatsby-route-change` })
                }}
                onAcceptPreferences = {() => {}}
                onAcceptStatistics = {() => {}}
                onAcceptMarketing = {() => {}}
              />
            </div>
        </>
    )
}

Layout.propTypes = {
    children: any,
}

export default Layout
