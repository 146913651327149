const BannerData = {
    AgencyText : [
        {
            id: 1,
            btitle : 'Ihr Partner für IT, Web- und App-Entwicklung! ' ,
            description : 'Buildnext ist ein Softwareentwicklungs-Unternehmen, welches Sie von der Planung, über die Implementierung bis zum Betrieb Ihrer Projekte begleitet. Egal ob Sie eine Individualsoftware benötigen, einen Prozess digitalisieren möchten oder eine App-Idee haben, von der Planung bis zur Realisierung begleiten wir Sie gern dabei.   ',
        }
    ],
    ClientsLogo: [
        {
            id : 1,
            image: 'logo_01.png'
        },
        {
            id : 2,
            image: 'logo_02.png'
        },
        {
            id : 3,
            image: 'logo_03.png',
        },
        {
            id : 4,
            image: 'logo_04.png',
        },
        {
            id : 5,
            image: 'logo_05.png'
        }
    ],
    AgencyTestimonial: [
        {
            id: 1,
            image: 'author_img.png',
            authorName: 'Lurch Schpellchek',
            authorPost: 'UI/UX designer',
            description: 'What a load of rubbish bits and bobs pear shaped owt to do with me bubble and squeak jolly good morish tinkety tonk old fruit, car boot my good sir buggered plastered cheeky David, haggle young delinquent say so I said bite your arm off easy peasy. Skive off it`s all gone to pot buggered.',
        },
        {
            id: 2,
            image: 'author_img.png',
            authorName: 'Lurch Schpellchek',
            authorPost: 'UI/UX designer',
            description: 'What a load of rubbish bits and bobs pear shaped owt to do with me bubble and squeak jolly good morish tinkety tonk old fruit, car boot my good sir buggered plastered cheeky David, haggle young delinquent say so I said bite your arm off easy peasy. Skive off it`s all gone to pot buggered.',
        }
    ]
}
export default BannerData;
