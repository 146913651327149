import React from 'react';
import Reveal from 'react-reveal/Reveal';

const KnowHow = () => {
    return(
        <>
        <a className="anchor100" id="know_how_area" />
        <section className="payment_clients_area">

            <div className="clients_bg_shape_top"/>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <Reveal effect="fadeInLeft" duration={500}>
                            <div className="payment_features_content pr_70">
                                <h2>Know how</h2>
                              <p>Moderne Technologien sind die Leidenschaft unserer Entwickler. Wir bieten Ihnen Entwickler mit Know How in einer Vielzahl von Frameworks. Gemeinsam entscheiden wir anhand Ihrer Anforderungen, welche Technologie am besten zu Ihnen und Ihren Anforderungen passt.</p>
                                <p>Darf es ein Cloud Hosting sein oder ist ein On-Premise Hosting erforderlich? Unser Team hat in beiden Bereichen jahrelange Erfahrungen und berät Sie gerne.</p>
                                <a href="mailto:info@buildnext.io" className="btn_hover agency_banner_btn pay_btn pay_btn_two">Fragen?</a>
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-lg-6">
                        <div className="payment_clients_inner">
                            <Reveal effect="fadeInLeft" duration={500}>
                                <div className="clients_item one">
                                    <img src={require('../../img/logoskills/react.png')} alt="React Entwicklung" width="100"/>
                                </div>
                                <div className="clients_item two">
                                    <img src={require('../../img/logoskills/nodejslogo.png')} width="120" alt="nodejs Anwendung"/>
                                </div>
                                <div className="clients_item three">
                                <img src={require('../../img/logoskills/spring.png')} width="100" alt="Java Spring Entwicklung"/>
                                </div>
                                <div className="clients_item four">
                                <img src={require('../../img/logoskills/ios.png')} width="100" alt="iPhone App Entwicklung"/>
                                </div>
                                <div className="clients_item five">
                                <img src={require('../../img/logoskills/android.jpg')} width="100" alt="Android App Entwicklung"/>
                                </div>
                                <div className="clients_item six">
                                <img src={require('../../img/logoskills/docker.png')} width="90" alt="Docker Hosting"/>
                                </div>
                                <div className="clients_item seven">
                                <img src={require('../../img/logoskills/aws.png')} width="60" alt="AWS Amazon Web Services Hosting"/>
                                </div>
                                <div className="clients_item eight">
                                <img src={require('../../img/logoskills/angular.png')} width="60" alt="AngularJS Entwicklung"/>
                                </div>
                            </Reveal>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default KnowHow;
