import React, {Component} from 'react';

class CallToAction extends Component {
    render(){
        return(
            <section className="action_area_three sec_pad">
                <div className="curved"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="action_content text-center">
                                <a className="anchor100" id="contact_area" />
                                <h2 className="f_600 f_size_30 l_height45 mb_40">Interesse geweckt?</h2>
                                <a href="mailto:info@buildnext.io" className="about_btn white_btn wow fadeInLeft" data-wow-delay="0.3s">Wir möchten Sie kennenlernen!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default CallToAction;
