import React, {Component} from 'react';
import ServiceItem from "./ServiceItem";

class Services extends Component{
    render(){
        var {aClass} =this.props;
        return(
          <>
            <a className="anchor100" id="service_area" />
            <section className={`agency_featured_area bg_color ${aClass}`} id="service_area">
                <div className="container">
                    <h2 className="f_size_30 f_600 t_color3 l_height40 text-center wow fadeInUp" data-wow-delay="0.3s">Leistungen</h2>
                    <div className="features_info">
                        <img className="dot_img" src={require ('../../img/home4/dot.png')} alt="Dot"/>
                        <ServiceItem rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="work1.png" iImg="icon01.png" ftitle="Anforderungsanalyse" descriptions="In einem ersten Workshop lernen wir uns kennen und analysieren gemeinsam Ihre Anforderungen. Dabei definieren wir erste Features und entwicklen eine Roadmap. Dabei haben wir natürlich immer den zeitlichen Aspekt und den Budget Rahmen im Auge. "/>
                        <ServiceItem rowClass="row agency_featured_item_two" aClass="pl_100" fimage="work2.png" iImg="icon02.png" ftitle="Umsetzung" descriptions="Wir richten unserer Entwicklungsprozess nach agilen Methoden aus und sind so in der Lage schnell erste Ergebnisse zu zeigen, um so frühzeitig Änderungswünsche zu berücksichten."/>
                        <ServiceItem rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="work3.png" iImg="icon3.png" ftitle="Betrieb"
                        descriptions="Auch nach der Produktivnahme Ihres Projektes lassen wir Sie natürlich nicht im Regen stehen. Die Software kann auf Wunsch von uns weiter betreut und überwacht werden. So bleiben wir weiter Ihr erster Ansprechpartner bei Problemen oder Optimierungen."/>
                        <div className="dot middle_dot"><span className="dot1"></span><span className="dot2"></span></div>
                    </div>
                </div>
            </section>
          </>
        )
    }
}
export default Services;
